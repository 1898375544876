import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';
import { Card as IssueCard } from '../components/issues';
import { getPublishedIssues, removeBipSectionIssue } from '../model/issue';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, FreeMode } from 'swiper';
import 'swiper/scss';

export default function IssuesPage({
  data: {
    wpPage: { seo },
    wp: {
      siteOptionsMetaboxSettings: { optionsIssuesHomepage: issueIds },
    },
    allWpTghpTaxonomyIssue: { nodes: allIssues },
  },
}) {
  const publishedIssues = getPublishedIssues(issueIds, allIssues);
  const filteredPublishedIssues = removeBipSectionIssue(publishedIssues);

  return (
    <Layout location={'issues-listing'} seoData={seo}>
      <div className="issues-page">
        <div className="issues-page__issues-mobile">
          {filteredPublishedIssues.map((issue) => (
            <IssueCard issue={issue} showFeaturesBar={true} key={issue.databaseId} />
          ))}
        </div>
        <Swiper
          className={'issues-page__slider-desktop'}
          spaceBetween={25}
          slidesPerView={1.3}
          direction={'horizontal'}
          mousewheel={true}
          freeMode={{
            enabled: true,
            sticky: false,
          }}
          modules={[FreeMode, Mousewheel]}
          breakpoints={{
            720: {
              slidesPerView: 2.1,
            },
            980: {
              slidesPerView: 2.3,
            },
          }}
        >
          {filteredPublishedIssues.map((issue) => (
            <SwiperSlide key={issue.databaseId}>
              <IssueCard issue={issue} showFeaturesBar={true} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      ...SeoData
    }

    wp {
      ...SiteOptions
    }

    allWpTghpTaxonomyIssue(
      filter: { tghpwipIssuesTaxonomyCategory: { nin: ["film", "long read"] } }
    ) {
      nodes {
        ...AllIssues
      }
    }
  }
`;

export const Head = () => <HeadMeta />;
